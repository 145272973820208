<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">AUTO MAINTENANCE MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md4>
          <v-select
            v-model="category_id"
            dense
            outlined
            label="Cars"
            :items="category_items"
            item-value="id"
            item-text="car"
            :rules="rules.combobox_rule"
            @change="selected_category"
          ></v-select>
        </v-flex>
      </v-layout>
      <h2>Maintenance Data | Total: {{ total_amount }}</h2>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">Category</th>
            <th class="text-center text-uppercase">Branch</th>
            <th class="text-center text-uppercase">Details</th>
            <th class="text-center text-uppercase">Payment For</th>
            <th class="text-center text-uppercase">Date</th>
            <th class="text-center text-uppercase">Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in employee_items" :key="item.id">
            <td class="text-center">
              {{ item.category.category }}
            </td>
            <td class="text-center">
              {{ item.branch===null?'':item.branch.branch_code }}
            </td>
            <td class="text-center">
              {{ item.details? item.details:item.details_data.details}}
            </td>
            <td class="text-center">
              {{ item.payment_for }}
            </td>
            <td class="text-center">
              {{ item.date!=null? item.date:''}}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiDelete, mdiCheck, mdiCloseCircleOutline} from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      category_id: '',
      category_items: [],
      employee_items: [],
      total_amount: '0.00',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
          mdiCheck,
          mdiCloseCircleOutline,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('auto_maintenance', ['initialize_auto_maintenance_history']),
      initialize_data() {
        this.initialize_auto_maintenance_history()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        if (this.category_items.length > 0) {
          var index = this.category_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.category_id)
          if (index >= 0) {
            this.employee_items = this.category_items[index].data
            this.total_amount = this.compute_total(this.employee_items)
          }
        }
      },
      compute_total(data) {
        var tto = 0
        data.forEach(function (item) {
          tto += parseFloat(item.amount)
        })
        return this.formatPrice(tto)
      },
    },
  }
</script>
